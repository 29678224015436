import {useCallback, useEffect, useLayoutEffect} from 'react';

import {SurveyDialog} from '@/components/common/dialogs/survey-dialog/SurveyDialog';
import {ILightboxService} from '@/services/lightbox/ILightboxService';

import {useInjection} from './use-injection';
import {useLocalStorageBooleanValue} from './use-local-storage-boolean-value';
import {useSessionTime} from './use-session-time';

const SESSION_TIME_IN_SECONDS = 2 * 60;
const CURSOR_AND_DOCUMENT_CLOSE_BUTTON_MIN_DISTANCE_PX = 10;
const HAS_SHOWN_SURVEY_LIGHTBOX = 'hasShownSurveyLightbox';
const SURVEY_LIGHTBOX_SESSION_TIME_KEY = 'surveyKeySessionTime';

export function useSurveyLightbox() {
	const [hasShownLightbox, setShowLightbox] = useLocalStorageBooleanValue(HAS_SHOWN_SURVEY_LIGHTBOX, true);
	const {start, pause, promoSessionTime} = useSessionTime(SURVEY_LIGHTBOX_SESSION_TIME_KEY);
	const shouldActivateBanner = !hasShownLightbox;
	const isMatchSessionTime = promoSessionTime >= SESSION_TIME_IN_SECONDS;
	const [LightboxService] = useInjection(ILightboxService);

	useLayoutEffect(() => {
		if (!isMatchSessionTime) {
			start?.();
		}
	}, []);

	const openLightBox = useCallback(() => {
		LightboxService.openReadonly(SurveyDialog, {});
		setShowLightbox();
		pause();
	}, [LightboxService, pause, setShowLightbox]);

	// open by time
	useEffect(() => {
		if (shouldActivateBanner && isMatchSessionTime) {
			openLightBox();
		}
	}, [isMatchSessionTime, openLightBox, shouldActivateBanner]);

	// open by onclose
	useEffect(() => {
		if (!shouldActivateBanner) {
			return;
		}
		const listener = (event: MouseEvent) => {
			if (event.clientY < CURSOR_AND_DOCUMENT_CLOSE_BUTTON_MIN_DISTANCE_PX) {
				openLightBox();
			}
		};

		document.addEventListener('mouseleave', listener);
		return () => document.removeEventListener?.('mouseleave', listener);
	}, [openLightBox, shouldActivateBanner]);
}
