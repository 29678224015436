import {useEffect, useState} from 'react';

export function useLocalStorageBooleanValue(name: string, defaultValue = false): [boolean, () => void] {
	const [hasValue, setHasValue] = useState<boolean>(defaultValue);

	useEffect(() => {
		const savedValue = localStorage.getItem(name);
		setHasValue(savedValue === 'true');
	}, [name]);

	const setTrue = () => {
		setHasValue(true);
		localStorage.setItem(name, String(true));
	};

	return [hasValue, setTrue];
}
