import React from 'react';

import {Button} from '@/components/base/button/Button';
import {Dialog} from '@/components/base/dialog/Dialog';
import {LINKS} from '@/constants';
import {injectProperty} from '@/container/injections';
import {ILightboxService} from '@/services/lightbox/ILightboxService';
import {ReadonlyLightbox} from '@/services/lightbox/interfaces';
import {GA4EventId, YAReachGoalId} from '@/services/metrics/constants';
import {IMetricsService} from '@/services/metrics/IMetricsService';

import Image from '../../image/Image';
import img from './img.webp';
import styles from './SurveyDialog.module.css';

interface SurveyDialogProps {}

export class SurveyDialog extends ReadonlyLightbox<SurveyDialogProps> {
	@injectProperty(IMetricsService) protected readonly metricsService!: IMetricsService;
	@injectProperty(ILightboxService) protected lightboxService!: ILightboxService;

	componentDidMount() {
		this.metricsService.sendEvent({
			yaGoals: [YAReachGoalId.ShowSurveyLightBox],
			ga4Events: [GA4EventId.ShowSurveyLightBox],
		});
	}

	onButtonClick = () => {
		this.metricsService.sendEvent({
			yaGoals: [YAReachGoalId.ClickSurveyLightBox],
			ga4Events: [GA4EventId.ClickSurveyLightBox],
		});
		this.props.onClose();
	};

	render() {
		const {onClose} = this.props;
		return (
			<Dialog
				open
				onClose={onClose}
				className={styles.root}
				disableBackdropScroll
				closeButtonClassName={styles.closeButton}
			>
				<Dialog.Body>
					<div className={styles.content}>
						<div className={styles.title}>
							Автоматизируйте общение с&nbsp;клиентами при помощи рассылок
						</div>
						<div className={styles.descr}>
							<div className={styles.headline}>
								Пройдите опрос, чтобы повлиять на&nbsp;создание нового сервиса
							</div>
							<div className={styles.promo}>
								все, прошедшие опрос, получат промокод на&nbsp;скидку 30%
							</div>
						</div>
						<Button
							as="a"
							href={LINKS.survey}
							className={styles.button}
							onClick={this.onButtonClick}
						>
							Начать
						</Button>
					</div>
					<div className={styles.imgWrapper}>
						<Image
							className={styles.img}
							src={img}
							layout="responsive"
							alt="Картинка для акции"
						/>
					</div>
				</Dialog.Body>
			</Dialog>
		);
	}
}
