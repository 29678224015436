import {useCallback, useLayoutEffect, useState} from 'react';
import {useStopwatch} from 'react-timer-hook';

const BEGIN_TIME = 0;

const hasStorageTime = (key: string) => {
	return localStorage.getItem(key) !== null;
};

const getStorageTime = (key: string) => {
	const storageTime = localStorage.getItem(key);
	return storageTime === null ? BEGIN_TIME : parseInt(storageTime);
};

export function useSessionTime(key: string) {
	const [promoSessionTime, setPromoSessionTime] = useState(null);

	const setStorageTime = useCallback(
		(time: number) => {
			localStorage.setItem(key, `${time}`);
			setPromoSessionTime(time);
		},
		[key],
	);

	const {totalSeconds, start, pause} = useStopwatch({autoStart: false});

	useLayoutEffect(() => {
		if (!hasStorageTime(key)) {
			setStorageTime(BEGIN_TIME);
			return;
		}

		const currentTime = getStorageTime(key);
		if (totalSeconds !== currentTime) {
			setStorageTime(currentTime + 1);
		}
	}, [totalSeconds, setStorageTime, key]);

	return {start, pause, promoSessionTime};
}
